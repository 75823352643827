import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6cd90a2e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-container-02" }
const _hoisted_2 = {
  key: 0,
  class: "selected-value placeholder opa-07"
}
const _hoisted_3 = {
  key: 1,
  class: "selected-value"
}
const _hoisted_4 = { class: "dropdown thin-scrollbar" }
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["input flex-center", { active: _ctx.isDropdownOpen }]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleDropdown && _ctx.toggleDropdown(...args)))
    }, [
      (!_ctx.selectedValue.label)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.placeholder), 1))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.selectedValue.label), 1)),
      _withDirectives(_createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localOptions, (option, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "dropdown-option",
            onClick: ($event: any) => (_ctx.selectOption(option))
          }, _toDisplayString((option as any).label), 9, _hoisted_5))
        }), 128))
      ], 512), [
        [_vShow, _ctx.isDropdownOpen]
      ])
    ], 2),
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: "",
          class: _normalizeClass(`label ${_ctx.isDropdownOpen ? 'active' : ''}`)
        }, _toDisplayString(_ctx.label), 3))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(`arrow ${_ctx.isDropdownOpen ? 'arrow-rotate' : ''}`)
    }, null, 2)
  ]))
}