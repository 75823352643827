
import { defineComponent } from "vue";
import { ElLoading } from "element-plus";
import CustomSelect from "@/components/Input/SelectBox.vue";
import helpers from "@/helpers/global";
import _ from "lodash";

export default defineComponent({
  name: "CreateAccount",
  components: {
    CustomSelect,
  },
  created() {
    if (localStorage.getItem("userData")) {
      this.$router.replace("/");
      return;
    }
    this.$store.dispatch("forceReady", null, { root: true });
  },
  watch: {
    error(value) {
      console.error(value);
    },
    userLogin(value) {
      if (value) {
        this.$router.push("/");
      }
    },
    loadingStatus(value) {
      const loading = ElLoading.service({
        lock: true,
        text: "Login..",
        background: "#ffffff90",
      });
      if (!value) {
        loading.close();
      }
    },
    form: {
      handler(value) {
        this.checkingValidate(value);
      },
      deep: true,
    },
    lang(value) {
      const setLocale = (lang: string) => {
        (this as any).$i18n.setLocale(lang);
      };
      setLocale(value);
    },
  },
  computed: {
    userLogin(): any {
      return this.$store.getters.getLoginStatus;
    },
    typeOfOrgOptions(): any[] {
      return this.$store.getters["toolbox/typeOfOrgOptions"];
    },
  },
  data() {
    return {
      validateEmail: false,
      form: {
        email: "",
        lang: "",
        language: "",
        firstname: "",
        lastname: "",
        jobTitle: "",
        companyName: "",
        typeOfOrgSelected: "",
        numberOfEmployee: "",

        // Compatible with previous version. Please be careful when changing.
        firstName: "",
        lastName: "",
      },
      validateForm: {
        email: false,
        language: false,
        firstname: false,
        lastname: false,
        jobTitle: false,
        companyName: false,
        typeOfOrgSelected: false,
        numberOfEmployee: false,
      },
      validateFormStatus: false,
      SelectedEmployee: {
        label: "",
        key: "",
      },
      numberOfEmployeeOptional: [
        {
          label: "1 - 49",
          key: "1 - 49",
        },
        {
          label: "50 - 199",
          key: "50 - 199",
        },
        {
          label: "200 - 499",
          key: "200 - 499",
        },
        {
          label: "500 - 999",
          key: "500 - 999",
        },
        {
          label: "More than 1,000",
          key: "More than 1,000",
        },
      ],
      selectedLanguage: {
        label: "",
        key: "",
      },
      language: [
        {
          label: "English",
          key: "en",
        },
        {
          label: "ภาษาไทย",
          key: "th",
        },
      ],
      lang: "en",
    };
  },
  mounted() {
    if (this.userLogin) {
      this.$router.push("/");
    }
    const email = this.$store.getters["auth/email"] || this.$route.query.email;
    this.form.email = email;
  },
  methods: {
    handleLanguageInput(newValue: any) {
      this.selectedLanguage = newValue;
      this.form.language = newValue.key;
      this.lang = newValue.key;
    },
    handleEmployeeInput(newValue: any) {
      this.SelectedEmployee = newValue;
      this.form.numberOfEmployee = newValue.key;
    },
    checkingValidate(value: any) {
      const validateForm = {
        // email: value.email && value.email !== "",
        language: value.language && value.language !== "",
        // jobTitle: value.jobTitle && value.jobTitle.length > 2,
        typeOfOrgSelected:
          value.typeOfOrgSelected && value.typeOfOrgSelected.length > 2,
        firstname: value.firstname && value.firstname.length > 2,
        lastname: value.lastname && value.lastname.length > 2,
        companyName: value.companyName && value.companyName.length > 2,
        numberOfEmployee:
          value.numberOfEmployee && value.numberOfEmployee !== "",
      };
      // check validate form
      const validateFormStatus = Object.values(validateForm).every(
        (item) => item
      );
      // if (showValidate) {
      //   this.validateForm.language = validateForm.language;
      //   this.validateForm.firstname = validateForm.firstname;
      //   this.validateForm.lastname = validateForm.lastname;
      //   this.validateForm.jobTitle = validateForm.jobTitle;
      //   this.validateForm.companyName = validateForm.companyName;
      //   this.validateForm.numberOfEmployee = validateForm.numberOfEmployee;
      // }
      this.validateFormStatus = validateFormStatus;
    },
    newStep() {
      // this.checkingValidate(this.form, true);
      if (this.validateFormStatus) {
        this.form.lang = this.form.language;
        const lang = this.form.lang;
        const setLocale = (lang: string) => {
          (this as any).$i18n.setLocale(lang);
        };
        setLocale(lang);
        localStorage.setItem("lang", lang.toUpperCase());

        this.form.firstName = this.form.firstname;
        this.form.lastName = this.form.lastname;

        let form = _.omit(this.form, [
          "firstname",
          "lastname",
          "typeOfOrgSelected",
        ]) as any;
        form.typeOfOrg = this.form.typeOfOrgSelected;
        this.$store.dispatch("auth/setSignupInfo", form, { root: true });

        const referralCode = helpers.getReferralCode();
        if (referralCode) {
          this.$router.push("/signup/branding?" + referralCode);
        } else {
          this.$router.push({ name: "SignupBranding" });
        }
      }
    },
  },
});
