
import { defineComponent } from "vue";

interface DropdownOption {
  key: string;
  label: string;
}

export default defineComponent({
  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({ label: "", key: "" }),
    },
    options: {
      type: Array as () => DropdownOption[],
      required: true,
      default: () => [] as DropdownOption[],
    },
    placeholder: {
      type: String,
      required: false,
      default: () => "",
    },
    label: {
      type: String,
      required: false,
      default: () => "",
    },
    disableAll: {
      type: Boolean,
      default: () => false,
    },
    multiple: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      isDropdownOpen: false,
      localOptions: [] as DropdownOption[],
      selectedValue: this.value,
    };
  },
  created() {
    this.localOptions = [...this.options];
    if (!this.disableAll) {
      this.pushDataToOptions();
    }
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    selectOption(option: any) {
      this.selectedValue = option;
      this.isDropdownOpen = false;
      this.$emit("input", option);
      this.toggleDropdown();
    },
    handleDocumentClick(event: MouseEvent) {
      if (this.isDropdownOpen && !this.$el.contains(event.target as Node)) {
        this.isDropdownOpen = false;
      }
    },
    pushDataToOptions() {
      // Example: Pushing new data to the localOptions array
      const newOption: DropdownOption = {
        label: "All",
        key: "",
      };
      this.localOptions.unshift(newOption);
    },
    setOptions(options: DropdownOption[]) {
      this.localOptions = options;
    },
  },
  mounted() {
    document.addEventListener("click", this.handleDocumentClick);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleDocumentClick);
  },
});
